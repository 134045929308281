/*
               _   (_)
    ___  ____ | |_  _  ___  ____   ___
   / _ \|  _ \|  _)| |/ _ \|  _ \ /___)
  | |_| | | | | |__| | |_| | | | |___ |
   \___/| ||_/ \___)_|\___/|_| |_(___/
        |_|
*/
stFinancement.prototype.calculPrixOptions = function (optionsSouscrites, apport, produit) {
    var totalOptions = 0;

    for (var key in optionsSouscrites) {
        if (optionsSouscrites[key] == 1) {
            var fnc = 'calcul' + key;
            if (['DI', 'IC'].includes(key)) {
                fnc = 'calcul' + produit + key;
            }
            var prixOption = this[fnc]();
            totalOptions += (isNaN(prixOption) ? 0 : prixOption);
        }
    }

    return totalOptions;
}
stFinancement.prototype.calculOptionSecuricar = function (montants) {
    var ageVehicule = this.getAgeVehicule();
    var ageVehiculeMois = Math.round(ageVehicule / 2629800);

    var puissanceKw = Math.round(this.puissanceDin * 0.736);
    var kilometrageVehicule = this.kilometrageVehicule;
    var typeVehicule = this.genre === 'VP' ? 'VP' : 'VU';

    if (this.energieCalculOption == null || puissanceKw == 0) {
        return 0;
    }

    var tmpMontants = montants[this.energieCalculOption][typeVehicule]

    for (var key in tmpMontants) {
        var evaluationKm = eval(key.replace('kilometrageVehicule' , kilometrageVehicule));
        if (evaluationKm) {
            for (var keyMois in tmpMontants[key]) {
                var evaluationAgeMois = eval(keyMois.replace('ageVehiculeMois' , ageVehiculeMois));
                if (evaluationAgeMois) {
                    for (var keyPuissance in tmpMontants[key][keyMois]) {
                        var evaluationPuissance = eval(keyPuissance.replace('puissanceKw' , puissanceKw));
                        if (evaluationPuissance) {
                            return parseFloat(tmpMontants[key][keyMois][keyPuissance]['prix']);
                        }
                    }
                }
            }
        }
    }

    return 0;
}
stFinancement.prototype.calculOptionMaintenance = function (montants) {
    var ageVehicule = this.getAgeVehicule();
    var ageVehiculeMois = parseFloat((ageVehicule / 2629800).toFixed(1));

    var puissanceKw = Math.round(this.puissanceDin * 0.736);
    var kilometrageVehicule = this.kilometrageVehicule;
    var typeVehicule = this.genre === 'VP' ? 'VP' : 'VU';

    if (puissanceKw == 0) {
        return 0;
    }

    // On cherche this.marque dans jsonGroupeMarque
    var groupeMarque = 0;
    for (var key in jsonGroupeMarque) {
        if (jsonGroupeMarque[key].includes(this.marque)) {
            groupeMarque = key;
            break;
        }
    }

    if (groupeMarque === 0) {
        return 0;
    }

    var tmpMontants = montants[groupeMarque][this.energieCalculOption][typeVehicule];
    for (var key in tmpMontants) {
        var evaluationKm = eval(key.replace('kilometrageVehicule' , kilometrageVehicule));
        if (evaluationKm) {
            for (var keyMois in tmpMontants[key]) {
                var evaluationAgeMois = eval(keyMois.replace('ageVehiculeMois' , ageVehiculeMois));
                if (evaluationAgeMois) {
                    for (var keyKmParAn in tmpMontants[key][keyMois]) {
                        var evaluationKmParAn = eval(keyKmParAn.replace('kmParAn' , this.kilometrage));
                        if (evaluationKmParAn) {
                            for (var keyPuissance in tmpMontants[key][keyMois][keyKmParAn]) {
                                var evaluationPuissance = eval(keyPuissance.replace('puissanceKw' , puissanceKw));
                                if (evaluationPuissance) {
                                    return parseFloat(tmpMontants[key][keyMois][keyKmParAn][keyPuissance]);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return 0;
}


stFinancement.prototype.getNonAvailableOptions = function () {
        var hasSegmentViaxel = (this.segmentViaxel && this.segmentViaxel.length > 0 && segmentsViaxel.indexOf(this.segmentViaxel) > -1) ? true : false;
        this.optionsNonEligibles = [];

        // DI - EX SECURIVIE
        if (eval(
            this.vxlvat['options']['DI']['eligibiliteMontant']
                .replace('montantTotal', this.getCapitalEmprunte())
        ) === false ) {
            this.optionsNonEligibles.push('DI');
        }
        // END DI - EX SECURIVIE

        // IC - EX SECURICAP
        if (eval(
            this.vxlvat['options']['IC']['eligibiliteVehicule']
                .replace('ageVehicule', this.getAgeVehicule())
                .replace('tenYears', tenYears)
                .replace('valeurAchatVehicule', this.prixVehiculeTTC)
                .replace('valeurAchatVehicule', this.prixVehiculeTTC)
                // On a pas toujours l'info, dans ce cas on autorise les VP mais bloque les VU
                .replace('ptac', this.ptac ? this.ptac : financement.genre !== 'CTTE' ? 0 : 5001)
        ) === false ) {
            this.optionsNonEligibles.push('IC');
        }
        // IC - END EX SECURICAP

        // EXTENSION GARANTIE - EX SECURICAR
        if (this.calculGARANTIE() === 0) {
            this.optionsNonEligibles.push('GARANTIE');
        }
        // END EXTENSION GARANTIE - EX SECURICAR

        // MAINTENANCE - EX SECURI3 ou SECURI4
        if (this.calculMAINTENANCE() === 0) {
            this.optionsNonEligibles.push('MAINTENANCE');
        }
        // END MAINTENANCE - EX SECURI3 ou SECURI4

        return this.optionsNonEligibles;
}

stFinancement.prototype.calculPrixOptionCoeff = function (valeur, coeff) {
    return valeur * coeff;
}

stFinancement.prototype.checkReglettesEligibilite = function (financementType, repriseMarchand = 0) {
    var errorDescription = [];

    var eligibiliteMensualiteBasse = this[financementType].eligibiliteMensualiteBasse.replace(/mensualite/g, this.duree).replace(/repriseMarchand/g, repriseMarchand);
    var eligibiliteMensualiteHaute = this[financementType].eligibiliteMensualiteHaute.replace(/mensualite/g, this.duree).replace(/repriseMarchand/g, repriseMarchand);
    var eligibiliteApport = this[financementType].eligibiliteApport.replace('apport', this.apport).replace('prixVehiculeTTC', this.prixVehiculeTTC);

    if (typeof this[financementType].eligibiliteKilometrage !== 'undefined' ) {
        var eligibiliteKilometrage = this[financementType].eligibiliteKilometrage.replace('kilometrageTotal', this.kilometrageTotal).replace(/repriseMarchand/g, repriseMarchand);
        if (eval(eligibiliteKilometrage) === false) {
            errorDescription['kilometrage'] = ">";
            errorDescription.error = true;
        }
    }

    if (eval(eligibiliteMensualiteBasse) === false) {
        errorDescription['durée'] = "<";
        errorDescription.error = true;
    }
    if (eval(eligibiliteMensualiteHaute) === false) {
        errorDescription['durée'] = ">";
        errorDescription.error = true;
    }
    if (eval(eligibiliteApport) === false) {
        errorDescription['apport'] = ">";
        errorDescription.error = true;
    }

    return errorDescription;
}

// ---- VAT ---- //
stFinancement.prototype.calculVXLVATIC = function () {
    var montant = this.calculPrixOptionCoeff(
        this.getCapitalEmprunte(),
        this.vxlvat['options']['IC']['valeurs']['coef']
    );

    if (montant <= this.vxlvat['options']['IC']['valeurs']['min']) {
        return this.vxlvat['options']['IC']['valeurs']['min'];
    }

    if (montant >= this.vxlvat['options']['IC']['valeurs']['max']) {
        return this.vxlvat['options']['IC']['valeurs']['max'];
    }

    return montant;
}
stFinancement.prototype.calculVXLVATDI = function () {
    return this.calculPrixOptionCoeff(
        this.getCapitalEmprunte(),
        this.vxlvat['options']['DI']['valeurs']['coef']
    );
}

// ---- LOA ---- //
stFinancement.prototype.calculVXLLOADI = function () {
    return this.calculPrixOptionCoeff(
        this.prixVehiculeTTC,
        this.vxlloa['options']['DI']['valeurs']['coef']
    )
}
stFinancement.prototype.calculVXLLOAIC = function () {
    var montant = this.calculPrixOptionCoeff(
        this.prixVehiculeTTC,
        this.vxlloa['options']['IC']['valeurs']['coef']
    );

    if (montant <= this.vxlloa['options']['IC']['valeurs']['min']) {
        return this.vxlloa['options']['IC']['valeurs']['min'];
    }

    if (montant >= this.vxlloa['options']['IC']['valeurs']['max']) {
        return this.vxlloa['options']['IC']['valeurs']['max'];
    }

    return montant;
}

// ---- LOAB ---- //
stFinancement.prototype.calculVXLLOABDI = function () {
    return this.calculVXLLOADI();
}
stFinancement.prototype.calculVXLLOABIC = function () {
    return this.calculVXLLOAIC();
}

// PROVAT
stFinancement.prototype.calculPROVATDI = function () {
    return this.calculPrixOptionCoeff(
        this.getCapitalEmprunte(),
        this.provat['options']['DI']['valeurs']['coef']
    );
}
stFinancement.prototype.calculPROVATIC = function () {
    var montant = this.calculPrixOptionCoeff(
        this.getCapitalEmprunte(),
        this.provat['options']['IC']['valeurs']['coef']
    );

    if (montant <= this.provat['options']['IC']['valeurs']['min']) {
        return this.provat['options']['IC']['valeurs']['min'];
    }

    if (montant >= this.provat['options']['IC']['valeurs']['max']) {
        return this.provat['options']['IC']['valeurs']['max'];
    }

    return montant;
}

// PROLOA
stFinancement.prototype.calculPROLOADI = function () {
    return this.calculPrixOptionCoeff(
        this.prixVehiculeTTC,
        this.proloa['options']['DI']['valeurs']['coef']
    );
}
stFinancement.prototype.calculPROLOAIC = function () {
    var montant = this.calculPrixOptionCoeff(
        this.prixVehiculeTTC,
        this.proloa['options']['IC']['valeurs']['coef']
    );

    if (montant <= this.proloa['options']['IC']['valeurs']['min']) {
        return this.proloa['options']['IC']['valeurs']['min'];
    }

    if (montant >= this.proloa['options']['IC']['valeurs']['max']) {
        return this.proloa['options']['IC']['valeurs']['max'];
    }

    return montant;
}
stFinancement.prototype.calculGARANTIE = function () {
    return this.calculOptionSecuricar(jsonExtensionGarantie);
}
stFinancement.prototype.calculMAINTENANCE = function () {
    return this.calculOptionMaintenance(jsonEntretien);
}

// ---- PRO LOAB ---- //
stFinancement.prototype.calculPROLOABDI = function () {
    return this.calculPROLOADI();
}
stFinancement.prototype.calculPROLOABIC = function () {
    return this.calculPROLOAIC();
}
